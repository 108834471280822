<script setup>
import { onMounted, ref } from 'vue'
import { list, edit } from '@/api/weChart.js'
import { message } from "ant-design-vue";

const PAGE_SIZE = 20

const pageNum = ref(1)
const pageSize = ref(PAGE_SIZE)

const formRef = ref()
const columns = [
  { title: "from_group", dataIndex: "fromGroup" },
  { title: "chat_id", dataIndex: "chatId" },
]
const source = ref([])
const loading = ref(false)
const pagination = ref({
  position: "bottom",
  total: 0, // 条数
  defaultPageSize: PAGE_SIZE, // 默认分页条数
  pageSizeOptions: ["5", "10", "15", "20", "30"],
  showSizeChanger: true, // 是否可以改变 pageSize
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `共 ${total} 条`,
  hideOnSinglePage: false, // 只有一页时是否隐藏分页器
  onChange: (selectedRowKeys, selectedRows) => {
    loading.value = true;
    getList({ pageNum: selectedRowKeys, pageSize: selectedRows });
  }
})

const addActive = () => {
  modalTitle.value = '新增微信群'
  formModal.value.data = {
    fromGroup: '', chatId: ''
  }
  modalVisible.value = true
}
const getList = async (params) => {
  pageNum.value = params.pageNum
  pageSize.value = params.pageSize
  const { data } = await list(params)
  const { records, total } = data
  source.value = records
  pagination.value.total = total
  loading.value = false
}
const refresh = () => getList({ pageNum: pageNum.value, pageSize: pageSize.value })

onMounted(refresh)

const modalTitle = ref('新增微信群')
const modalVisible = ref(false)
const modalLoading = ref(false)
const formModal = ref({
  data: {
    fromGroup: '', chatId: ''
  },
  rules: {
    fromGroup: [{ required: true, message: '必填项', trigger: 'blur' }],
    chatId: [{ required: true, message: '必填项', trigger: 'blur' }],
  }
})
const handleOk = async () => {
  formRef.value.validateFields().then(async () => {
    modalLoading.value = true
    const { status, msg } = await edit(formModal.value.data)
    status == '200' ? message.success(msg) : message.warning(msg)
  }).finally(() => {
    handlerCancel()
  })

}
const handlerCancel = () => {
  modalLoading.value = false
  modalVisible.value = false
  formRef.value.resetFields()
  refresh()
}
</script>
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="addActive">新增微信群</a-button>
  <a-table bordered rowKey="logicId" size="middle" :columns="columns" :data-source="source" :pagination="pagination"
    :loading="loading">
  </a-table>
  <a-modal :title="modalTitle" v-model:visible="modalVisible" :confirm-loading="modalLoading" @ok="handleOk"
    @cancel="handlerCancel" width="500px">
    <a-form ref="formRef" :model="formModal.data" :rules="formModal.rules" :label-col="{ span: 4 }"
      :wrapper-col="{ span: 20 }">
      <a-form-item label="from_group" name="fromGroup">
        <a-input v-model:value="formModal.data.fromGroup" />
      </a-form-item>
      <a-form-item label="chat_id" name="chatId">
        <a-input v-model:value="formModal.data.chatId" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
