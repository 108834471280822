import { request, request2 } from "../utils/axios";
import { stringify } from "qs";

// 微信群绑定列表
export function list(params) {
  return request({
    url: `/wuan/admin/wx/groupBound/page?${stringify(params)}`,
    method: "get",
  });
}

// 新增微信群绑定
export function edit(params) {
  return request2({
    url: `/wx/groupBound/save?${stringify(params)}`,
    method: "post",
  });
}
